import type { AdditionalProduct, Recipe } from '@ruokaboksi/api-client';
import { acceptHMRUpdate, defineStore } from 'pinia';
import type { VNode } from 'vue';

// Define modal types to distinguish between different modal content
export enum ModalType {
  Generic = 'Generic',
  Offer = 'Offer',
  Product = 'Product',
}

export enum OfferModalType {
  ActiveCalendarModal = 'ActiveCalendarModal',
  OfferModal = 'OfferModal',
}

type ModalActions = null | VNode;
type ModalContent = null | VNode;

interface GenericModalState {
  actions: ModalActions;
  content: ModalContent;
  isVisible: boolean;
  title: string;
  dismissable: boolean;
}

interface OfferModalState {
  modalList: Set<OfferModalType>;
}

export interface ProductModalState {
  id: string;
  product: AdditionalProduct | Recipe | null;
}

interface ModalState {
  generic: GenericModalState;
  offer: OfferModalState;
  product: ProductModalState;
  activeModalType: ModalType | null;
}

const initialGenericModalState: GenericModalState = {
  actions: null,
  content: null,
  isVisible: false,
  title: '',
  dismissable: true,
};

const initialOfferModalState: OfferModalState = {
  modalList: new Set<OfferModalType>(),
};

const initialProductModalState: ProductModalState = {
  id: '',
  product: null,
};

const useModalStore = defineStore('ModalStore', {
  state: (): ModalState => ({
    generic: { ...initialGenericModalState },
    offer: { ...initialOfferModalState },
    product: { ...initialProductModalState },
    activeModalType: null,
  }),

  actions: {
    openModal(
      modalType: ModalType,
      options: {
        actions?: ModalActions;
        content?: ModalContent;
        title?: string;
        offerType?: OfferModalType;
        id?: string;
        product?: AdditionalProduct | Recipe | null;
        dismissable?: boolean;
      } = {}
    ): void {
      this.activeModalType = modalType;

      switch (modalType) {
        case ModalType.Generic:
          this.generic.actions = options.actions || null;
          this.generic.content = options.content || null;
          this.generic.title = options.title || '';
          this.generic.isVisible = !!options.content;
          this.generic.dismissable = options.dismissable ?? true;
          break;

        case ModalType.Offer:
          if (options.offerType) {
            this.offer.modalList.add(options.offerType);
          }
          break;

        case ModalType.Product:
          this.product.id = options.id || '';
          this.product.product = options.product || null;
          break;
      }
    },

    closeModal(modalType: ModalType, offerType?: OfferModalType): void {
      switch (modalType) {
        case ModalType.Generic:
          this.generic.isVisible = false;
          setTimeout(() => {
            this.generic = { ...initialGenericModalState };
          }, 300);
          break;

        case ModalType.Offer:
          if (offerType) {
            this.offer.modalList.delete(offerType);
          }
          break;

        case ModalType.Product:
          this.product = { ...initialProductModalState };
          break;
      }

      if (!this.isAnyModalVisible) {
        this.activeModalType = null;
      }
    },
  },

  getters: {
    isOfferModalVisible: (state) => (offerType: OfferModalType) => {
      return state.offer.modalList.has(offerType);
    },

    isProductModalVisible: (state) => {
      return state.product.product !== null;
    },

    isGenericModalVisible: (state) => {
      return state.generic.isVisible;
    },

    isAnyModalVisible: (state) => {
      return (
        state.generic.isVisible ||
        state.offer.modalList.size > 0 ||
        state.product.product !== null
      );
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModalStore, import.meta.hot));
}

export default useModalStore;
