import type { TrackingConsent } from '~/models';

export type ConsentCategory =
  | 'necessary'
  | 'statistics'
  | 'marketing'
  | 'preferences';

export const trackingConsentCategoryMap: Record<
  keyof TrackingConsent,
  'necessary' | 'statistics' | 'marketing' | 'preferences'
> = {
  trackingConsentAnalytics: 'statistics',
  trackingConsentMarketing: 'marketing',
  trackingConsentPreferences: 'preferences',
};

export const saveConsentCookies = (newConsent: TrackingConsent) => {
  const cookieConsent = useCookie('cookie_consent');

  const categories =
    (cookieConsent.value as unknown as CookieConsent)?.categories.map(
      (c) => c
    ) || [];

  const newConsentCategories = Object.entries(newConsent)
    .filter(([_, value]) => value === true)
    .map(([key]) => trackingConsentCategoryMap[key as keyof TrackingConsent]);

  // Compare the new consent categories with the old ones, and update if they differ
  if (
    categories.sort().toString() !== newConsentCategories.sort().toString() ||
    !cookieConsent.value
  ) {
    const newConsentCookie: CookieConsent = {
      categories: newConsentCategories,
      revision: 0,
      data: null,
      consentTimestamp: new Date().toISOString(),
      consentId: 'custom-cookie-consent',
      services: newConsentCategories.reduce(
        (acc, category) => ({ ...acc, [category]: [category] }),
        { necessary: ['necessary'] } as Record<
          ConsentCategory,
          ConsentCategory[]
        >
      ),
      lastConsentTimestamp: new Date().toISOString(),
      expirationTime: new Date().getTime() + 6 * 30 * 24 * 60 * 60 * 1000, // 6 months from now
    };

    cookieConsent.value = newConsentCookie as unknown as string;
  }
};
