import MainHeading from '@/components/MainHeading';

export default defineNuxtComponent({
  name: 'ErrorGeneral',
  setup() {
    const { countryProperties } = useCurrentCountry();
    return { countryProperties };
  },
  render(): VNode {
    return (
      <div>
        <MainHeading>{this.$t('error.general_title')}</MainHeading>
        <div class="mt-8">
          <p>{this.$t('error.general_description')}</p>
          <p class="mt-2">
            <a
              class="text-current! no-underline! hover:underline!"
              href={`tel:${this.countryProperties.phone}`}
            >
              {this.countryProperties.phone}
            </a>
          </p>
          <p class="mt-2">
            <a
              class="text-current! no-underline! hover:underline!"
              href={`mailto:${this.countryProperties.email}`}
            >
              {this.countryProperties.email}
            </a>
          </p>
        </div>
      </div>
    );
  },
});
