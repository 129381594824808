import { formatPrice } from '@ruokaboksi/utils';

export const useCustomerCurrency = () => {
  const { countryProperties } = useCurrentCountry();

  return {
    currency: countryProperties.currency,
    formatPrice: (price: number | undefined) =>
      formatPrice(price, countryProperties.currency),
  };
};
