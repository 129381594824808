import posthog from 'posthog-js';
import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin(() => {
  const { posthogKey } = useRuntimeConfig().public;

  const posthogClient = posthog.init(posthogKey, {
    api_host: '/ingest',
    ui_host: 'https://eu.posthog.com',
    opt_out_capturing_by_default: true,
    capture_pageview: false, // we add manual pageview capturing below
    debug: process.env.NODE_ENV === 'development' ? true : false,
    autocapture: false,
  });

  // Make sure that pageviews are captured with each route change
  const router = useRouter();
  router.afterEach((to) => {
    nextTick(() => {
      posthog.capture('$pageview', {
        current_url: to.fullPath,
      });
    });
  });

  return {
    provide: {
      posthog: () => posthogClient,
    },
  };
});
