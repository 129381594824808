import LoadingEggs from '@/components/LoadingEggs';
import { getCertificateLogo, getGreenLogo } from '@/utils/logo';
import type { VNode } from 'vue';

export default defineComponent({
  name: 'LoadingScreen',
  props: {
    loadingTextKey: {
      default: 'global.loading',
      type: String,
    },
  },
  setup() {
    const { localeProperties } = useI18n();

    const logo = computed(() => getGreenLogo(localeProperties.value.brand));
    const certificateLogo = computed(() =>
      getCertificateLogo(localeProperties.value.brand)
    );

    return {
      logo,
      certificateLogo,
    };
  },
  render(): VNode {
    return (
      <aside class="min-w-screen z-30 grid min-h-[calc(100vh-6rem)] max-w-72 place-content-center bg-white text-center">
        {this.certificateLogo && <this.certificateLogo class="mx-auto w-8" />}
        <this.logo class="mx-auto mb-4 mt-3 block w-full max-w-72" />
        <LoadingEggs />
      </aside>
    );
  },
});
