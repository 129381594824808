import type { DeliveryWeekBase, Subscription } from '@ruokaboksi/api-client';
import { Week } from '@ruokaboksi/utils';

export type DeliveryWeekWithFormattedDays = DeliveryWeekBase & {
  weekString: string;
  weekNumberString: string;
};

export const addWeekProperties = (
  weeks: DeliveryWeekBase[],
  subscription: Subscription
): DeliveryWeekWithFormattedDays[] =>
  weeks.map((week) => {
    const weekObject = Week.fromPaymentDate(
      new Date(week.paymentDate),
      subscription.defaultDeliverySlot
    );
    return {
      ...week,
      weekString: weekObject.toYyyyWww(),
      weekNumberString: weekObject.isoWeek.toString(),
    };
  });
