import type { Customer, DraftableCustomer } from '@ruokaboksi/api-client';

export function useCurrentCustomer<T extends boolean = false>(
  includeDrafts?: T
): {
  currentCustomer: ComputedRef<
    T extends true ? DraftableCustomer | undefined : Customer | undefined
  >;
  isFetchedCurrentCustomer: Ref<boolean>;
  migrationId: ComputedRef<number | null>;
} {
  const { getMyCustomerInfo } = useCustomerApi();
  const { data: customerData, isFetched: isFetchedCurrentCustomer } =
    getMyCustomerInfo();

  const currentCustomer = computed(() => {
    const customer = customerData.value;
    if (!customer) return undefined;

    if (!includeDrafts && customer.isDraft) return undefined;
    return customer;
  }) as ComputedRef<
    T extends true ? DraftableCustomer | undefined : Customer | undefined
  >;

  const migrationId = computed<number | null>(
    () => currentCustomer.value?.migrationId || null
  );

  return { currentCustomer, isFetchedCurrentCustomer, migrationId };
}
