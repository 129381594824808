import posthog from 'posthog-js';

/**
 * This component does three things:
 *
 * 1. Evaluates which scripts will be loaded based on customer preferences.
 * 2. Disables Sentry if the analytics consent has not been given.
 * 3. Opt-ins to PostHog if the analytics consent has been given.
 * 4. Establishes a route guard to send page views whenever the
 * route changes, provided the customer has consented to marketing.
 */
export default defineComponent({
  name: 'EvaluateScripts',
  setup() {
    const route = useRoute();
    const shouldRunScripts = computed<boolean>(() => {
      const arr = [
        ...USER_REQUIRED_WHITELIST.filter((val) => {
          return !ECOM_PAGES.includes(val);
        }),
      ];

      return !arr.includes(route.path);
    });

    if (!shouldRunScripts.value) {
      return { shouldRunScripts };
    }

    const { isFetchedCurrentCustomer } = useCurrentCustomer();

    const { checkCustobarStatus, sendPageView } = useCustobar();
    const { initTracklution } = useTracklution();
    const { $sentryDisable } = useNuxtApp();
    const { hasAnalyticsConsent, hasMarketingConsent, hasPreferencesConsent } =
      useTrackingConsent();
    const { checkUserGuidingStatus } = useUserGuiding();

    const router = useRouter();
    // Skips page view if the customer has not consented to marketing
    router.afterEach(sendPageView);
    onMounted(() => {
      if (!shouldRunScripts.value) {
        return;
      }
      // Disable Sentry after mounting if no consent
      watchEffect(() => {
        if (ECOM_PAGES.includes(route.path) && hasMarketingConsent.value) {
          initTracklution();
        }
        if (hasAnalyticsConsent.value === false) {
          posthog.opt_out_capturing();
          $sentryDisable();
        }
        if (hasAnalyticsConsent.value === true) {
          // Opt-in to PostHog if the customer has given consent, and capture the initial page view
          if (posthog.has_opted_out_capturing()) {
            posthog.opt_in_capturing();
            posthog.capture('$pageview');
          }
        }
      });
    });

    watch([isFetchedCurrentCustomer, hasMarketingConsent], checkCustobarStatus);
    watch(
      [isFetchedCurrentCustomer, hasPreferencesConsent],
      checkUserGuidingStatus
    );

    return { shouldRunScripts };
  },
  render(): VNode {
    return <>{this.$slots.default?.()}</>;
  },
});
