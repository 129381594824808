import { useQuery } from '@tanstack/vue-query';
import type { ComputedRef } from 'vue';
import useApiClient from './useApiClient';

const KEY_CAMPAIGN_LINK_BY_SUBSCRIPTION_ID = 'campaignLinkBySubscriptionId';
const KEY_CAMPAIGN_BY_ID = 'campaignById';

/**
 * Composable for fetching campaign information.
 */
export default function useCampaignsApi() {
  const apiClient = useApiClient();
  const { locale } = useLocale();
  const { market } = useCurrentMarket();

  const getCampaignbyId = (campaignId?: ComputedRef) => {
    const queryFn = async () => {
      if (!campaignId?.value) return null;

      return await apiClient.campaigns.retrieve({
        market: market.value,
        id: campaignId.value,
      });
    };

    const enabled = computed<boolean>(() => !!campaignId);
    const queryKey = [KEY_CAMPAIGN_BY_ID, campaignId];
    return useQuery({
      queryKey,
      queryFn,
      refetchOnWindowFocus: false,
      enabled,
    });
  };

  const getLinkedSubscriptionCampaign = (
    subscriptionId: ComputedRef<number | undefined>
  ) => {
    const enabled = computed<boolean>(() => !!subscriptionId.value);
    const queryFn = async () => {
      if (!subscriptionId.value) return null;
      // 204 No Content and an empty string is returned if there is no campaign link
      return await apiClient.subscriptions.retrieveSubscriptionCampaign({
        customerId: '_me',
        subscriptionId: subscriptionId.value,
        ...(locale.value ? { language: locale.value } : {}),
      });
    };

    const queryKey = [KEY_CAMPAIGN_LINK_BY_SUBSCRIPTION_ID, subscriptionId];
    return useQuery({
      queryKey,
      queryFn,
      enabled,
      staleTime: 5 * 1000,
      refetchOnWindowFocus: false,
    });
  };

  return {
    getLinkedSubscriptionCampaign,
    getCampaignbyId,
  };
}
