import type { WindowWithUserGuiding } from '@/types';

interface UserGuidingComposable {
  checkUserGuidingStatus: () => void;
}
const USER_GUIDING_SCRIPT_ID = 'user-guiding-js';

/**
 * UserGuiding tracking script adapted for Nuxt.
 * @see {@link https://help.userguiding.com/en/articles/3420081-userguiding-javascript-api UserGuiding.js tracking script}
 */
export default function useUserGuiding(): UserGuidingComposable {
  const { currentUserUid } = useCurrentUser();
  const { hasCampaign } = useCustomerCampaigns();
  const { getMyCustomerInfo } = useCustomerApi();
  const { data: customerData } = getMyCustomerInfo();
  const {
    hasSubscription,
    hasDefaultPaymentMethod,
    isRenewalBlocked,
    isSubscriptionPaused,
    subscription,
  } = useCustomerSubscriptions();
  const { hasPreferencesConsent } = useTrackingConsent();

  /**
   * Company token for UserGuiding instance.
   * @see {@link https://panel.userguiding.com/settings/installation Token for production}
   */
  const userGuidingCompanyToken =
    useRuntimeConfig().public.userGuidingCompanyToken;

  /**
   * Script tag that should be injected in the <head> section of the page.
   * @see {@link https://nuxt.com/docs/api/composables/use-head useHead()} documentation
   */
  const userGuidingScript = computed<string>(() =>
    userGuidingCompanyToken
      ? `(function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];
        var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';
        f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};
        ug.c=function(n){return function(){ug.q.push([n,arguments])};};
        var m=['previewGuide','finishPreview','track','identify','triggerNps','hideChecklist','launchChecklist'];
        for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','${userGuidingCompanyToken}');`
      : ''
  );

  /** UserGuiding instance. */
  const userGuiding = computed<WindowWithUserGuiding | undefined>(
    () => (window as WindowWithUserGuiding) || undefined
  );

  const identifyCustomerToUserGuiding = (): void => {
    if (
      !currentUserUid.value ||
      !userGuiding.value?.userGuiding ||
      !userGuiding.value?.userGuidingLayer
    ) {
      return;
    }
    userGuiding.value.userGuidingLayer.push({
      event: 'onload',
      fn: () => {
        if (!userGuiding.value?.userGuiding) {
          return;
        }
        userGuiding.value?.userGuiding?.identify(currentUserUid.value);
        userGuiding.value?.userGuiding?.track('segment', {
          hasSubscription: hasSubscription.value,
          hasPaymentMethod: hasDefaultPaymentMethod.value,
          hasActiveCampaign: hasCampaign.value,
          language: customerData.value?.language,
          isRenewalBlocked: isRenewalBlocked.value,
          isSubscriptionPaused: isSubscriptionPaused.value,
          deliveryAreaId:
            subscription.value?.defaultDeliverySlot?.deliveryAreaId,
        });
      },
    });
  };

  const checkUserGuidingStatus = (): void => {
    const existingScript = document.getElementById(
      USER_GUIDING_SCRIPT_ID
    ) as HTMLScriptElement | null;
    /** Containers injected by the UserGuiding script. Needed for removal. */
    const additionalContainers: HTMLElement[] = Array.from(
      document.querySelectorAll(
        '#__ug__client__styled__root__, #__userGuiding__preview_Root'
      )
    );
    if (
      hasPreferencesConsent.value &&
      !existingScript &&
      userGuidingScript.value
    ) {
      const scriptElement = document.createElement('script');
      scriptElement.setAttribute('id', USER_GUIDING_SCRIPT_ID);
      scriptElement.innerHTML = userGuidingScript.value;
      document.head.appendChild(scriptElement);
      nextTick(identifyCustomerToUserGuiding);
    } else if (
      !hasPreferencesConsent.value &&
      (existingScript || additionalContainers.length)
    ) {
      existingScript?.remove();
      additionalContainers.forEach((container) => container.remove());
    }
  };

  return {
    checkUserGuidingStatus,
  };
}
