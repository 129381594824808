import type { TrackingConsent } from '@/models';
import { useQueryClient } from '@tanstack/vue-query';
import type { Ref } from 'vue';

export const clearConsentCookie = () => {
  useCookie('cookie_consent').value = null;
};

export interface CookieConsent {
  categories: ConsentCategory[];
  revision: number;
  data: unknown;
  consentTimestamp: string;
  consentId: string;
  services: Record<ConsentCategory, ConsentCategory[]>;
  lastConsentTimestamp: string;
  expirationTime: number;
}

export default function useTrackingConsent() {
  const { currentCustomer, isFetchedCurrentCustomer } =
    useCurrentCustomer(true);
  const queryClient = useQueryClient();
  const apiClient = useApiClient();

  const hasAnalyticsConsent = computed<boolean | null>(
    () => hasConsentGeneric('trackingConsentAnalytics').value
  );

  const hasMarketingConsent = computed<boolean | null>(
    () => hasConsentGeneric('trackingConsentMarketing').value
  );

  const hasPreferencesConsent = computed<boolean | null>(
    () => hasConsentGeneric('trackingConsentPreferences').value
  );

  const hasSetConsent = () => {
    return !!useCookie('cookie_consent').value;
  };

  /**
   * Internal implementation of has<X>Consent.
   */
  const hasConsentGeneric = (
    fieldName: keyof TrackingConsent
  ): Ref<boolean | null> => {
    if (!isFetchedCurrentCustomer.value) {
      const cookieConsent = useCookie('cookie_consent');

      const cookieConsentCategories = (
        cookieConsent.value as unknown as CookieConsent
      )?.categories;

      return ref(
        cookieConsentCategories?.includes(
          trackingConsentCategoryMap[fieldName]
        ) ?? null
      );
    }

    const data = currentCustomer.value;
    if (!data || data[fieldName] === undefined) return ref(null);

    return ref(data[fieldName]);
  };

  const saveConsent = async (newConsent: TrackingConsent) => {
    if (isFetchedCurrentCustomer.value) {
      await apiClient.customers.update({
        data: newConsent,
        customerId: '_me',
      });

      await queryClient.invalidateQueries({ queryKey: [KEY_MY_CUSTOMER_INFO] });
      await queryClient.invalidateQueries({
        queryKey: [KEY_MY_CUSTOMER_STATE],
      });
    }
    saveConsentCookies(newConsent);
  };

  return {
    hasSetConsent,
    hasAnalyticsConsent,
    hasMarketingConsent,
    hasPreferencesConsent,
    saveConsent,
  };
}
