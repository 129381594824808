import type { Subscription } from '@ruokaboksi/api-client';

/**
 * Customer subscriptions and related statuses.
 */
export default function useCustomerSubscriptions() {
  const { getCustomerSubscriptions } = useDeliveriesApi();

  const {
    data: subscriptions,
    isFetched: isFetchedSubscriptions,
    isLoading: isLoadingSubscriptions,
  } = getCustomerSubscriptions();

  /** Customer subscription */
  const subscription = computed<Subscription | null>(() =>
    subscriptions.value
      ? subscriptions.value.filter((s) => s.status !== 'cancelled')[0]
      : null
  );

  /** @returns `true` if the customer has one or more subscriptions. */
  const hasSubscription = computed<boolean>(
    () => !!subscriptions.value?.length
  );

  /** Customer subscription ID */
  const subscriptionId = computed<number | undefined>(
    () => subscription.value?.id
  );

  /** @returns `true` if subscription cannot be renewed. */
  const isRenewalBlocked = computed<boolean>(
    () => isFetchedSubscriptions.value && !!subscription.value?.renewalBlocked
  );

  /** @returns `true` if subscription is currently active. */
  const isSubscriptionActive = computed<boolean>(
    () => subscription.value?.status === 'active'
  );

  /** @returns `true` if subscription is currently paused. */
  const isSubscriptionPaused = computed<boolean>(
    () => subscription.value?.status === 'paused'
  );

  /**
   * Reason for not being able to renew the subscription.
   */
  const renewalBlockedReasons = computed<
    Subscription['renewalBlockedReason'] | undefined
  >(() => subscription.value?.renewalBlockedReason);

  const hasDefaultPaymentMethod = computed<boolean>(
    () => !!subscription.value?.billingOptionId
  );

  return {
    hasDefaultPaymentMethod,
    hasSubscription,
    isFetchedSubscriptions,
    isLoadingSubscriptions,
    isRenewalBlocked,
    isSubscriptionActive,
    isSubscriptionPaused,
    renewalBlockedReasons,
    subscription,
    subscriptionId,
    subscriptions,
  };
}
