import payload_plugin_K629R2esWBjSAINQVKCTWyLt1kpEfI2gpHzIMMmaGnE from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_nJbkLYoq9al4DVGfrwGpNwdzon5eBsXzl0lCDbxxrUY from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_b_f811dTL_awBaZhYH08f8Db_bQMMlQYlpMPtoe7n7c from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_c9PFreqQNE_iGlx42BDS6BQS1hFKBIyCGsfBIJAvFH8 from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_rJRQK0TMBboZcUDIqsAuPu934XiL5qAAxDdxrZ__0r8 from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_GLGsFbulfUN8qOrjV1Oe2ISND_S49zh7HE9A1O6ja34 from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Io86IcoxvWG2W3X04r0dtuH7OES9LYBjzZEgn4l2OBw from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_8M_OwQXeaqnJAr8q0xH4PT1WYzJA1AnH7MVH40ql_G0 from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_OXQZF4SIGwKvC2ltUuLC9__N0H6iOIQ_1DX3zcol9_c from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/clients/members/.nuxt/components.plugin.mjs";
import prefetch_client_usSU70_PwDtu0IWV9YOSUBm27VIyY0wu21VUrO_zP1c from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_Q4QG_JWrFkldguQIG03zqcO_kK_4AUpve2dv3MvQ_1I from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_XKVBybqs_BXvMN1URnuJp0XGMyBFmJaJQkYoeau32jM from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_19v21r6K1zdMR19WY1buFv7wSXagWynDr1PPElFzQrU from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg from "/app/clients/members/.nuxt/pwa-icons-plugin.ts";
import pwa_client_sNIK35dbPGd_WwtjVFUJK9wjyQjnbK3qUEuuKItbelA from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import firebase_client_CoJHhrT3ZeDUrul2mpvKodStAmSgY7mH391tLYlN4AA from "/app/clients/members/plugins/firebase.client.ts";
import posthog_client_2oml6AGWFDC739NVqwRmPEFqVxgr0onP_SqUR62_ALw from "/app/clients/members/plugins/posthog.client.ts";
import pwa_client_FJwzeegYDpFhjXgvIvQslGKRerMPgQavqBBhIXjOHyY from "/app/clients/members/plugins/pwa.client.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/app/clients/members/plugins/sentry.client.ts";
import vue_query_GNtWx11mSO94O_Ctd2OG8SaawZMBzi1ne17Edj_iIOc from "/app/clients/members/plugins/vue-query.ts";
import ssg_detect_JQT9b_sVERYDW__eN0wuiqDH6nql0nVptMbJ3IvFy8E from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_K629R2esWBjSAINQVKCTWyLt1kpEfI2gpHzIMMmaGnE,
  revive_payload_client_nJbkLYoq9al4DVGfrwGpNwdzon5eBsXzl0lCDbxxrUY,
  unhead_b_f811dTL_awBaZhYH08f8Db_bQMMlQYlpMPtoe7n7c,
  router_c9PFreqQNE_iGlx42BDS6BQS1hFKBIyCGsfBIJAvFH8,
  payload_client_rJRQK0TMBboZcUDIqsAuPu934XiL5qAAxDdxrZ__0r8,
  navigation_repaint_client_GLGsFbulfUN8qOrjV1Oe2ISND_S49zh7HE9A1O6ja34,
  check_outdated_build_client_Io86IcoxvWG2W3X04r0dtuH7OES9LYBjzZEgn4l2OBw,
  chunk_reload_client_8M_OwQXeaqnJAr8q0xH4PT1WYzJA1AnH7MVH40ql_G0,
  plugin_vue3_OXQZF4SIGwKvC2ltUuLC9__N0H6iOIQ_1DX3zcol9_c,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_usSU70_PwDtu0IWV9YOSUBm27VIyY0wu21VUrO_zP1c,
  switch_locale_path_ssr_Q4QG_JWrFkldguQIG03zqcO_kK_4AUpve2dv3MvQ_1I,
  route_locale_detect_XKVBybqs_BXvMN1URnuJp0XGMyBFmJaJQkYoeau32jM,
  i18n_19v21r6K1zdMR19WY1buFv7wSXagWynDr1PPElFzQrU,
  pwa_icons_plugin_C24GcIKjcI2zsa8A86om0L2LZjx1chWtzYxD11T7Txg,
  pwa_client_sNIK35dbPGd_WwtjVFUJK9wjyQjnbK3qUEuuKItbelA,
  firebase_client_CoJHhrT3ZeDUrul2mpvKodStAmSgY7mH391tLYlN4AA,
  posthog_client_2oml6AGWFDC739NVqwRmPEFqVxgr0onP_SqUR62_ALw,
  pwa_client_FJwzeegYDpFhjXgvIvQslGKRerMPgQavqBBhIXjOHyY,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  vue_query_GNtWx11mSO94O_Ctd2OG8SaawZMBzi1ne17Edj_iIOc,
  ssg_detect_JQT9b_sVERYDW__eN0wuiqDH6nql0nVptMbJ3IvFy8E
]