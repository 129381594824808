import * as Sentry from '@sentry/vue';
import type { App } from 'vue';

export default defineNuxtPlugin(() => {
  if (import.meta.server) {
    throw new Error('This Sentry plugin SHOULD NOT BE CALLED ON SERVER!');
  }

  const { vueApp, $firebaseAuth } = useNuxtApp();

  const { sentryDsn } = useRuntimeConfig().public;

  const sentryClient = new SentryClient(vueApp);

  // We only want to initialize Sentry if the DSN number provided.
  // For local development we don't need it.
  if (sentryDsn) {
    sentryClient.enable();

    $firebaseAuth.onAuthStateChanged((user): void => {
      if (user) {
        sentryClient.setUser(user.uid);
      } else {
        sentryClient.setUser(null);
      }
    });
  }

  return {
    provide: {
      sentry: Sentry,
      /** Allows disabling the Sentry SDK after app is mounted.
       * The function may be called several times (only one disabling is done).
       */
      sentryDisable: () => sentryClient.disable(),
    },
  };
});

type SentryState = 'not-initialized' | 'initialized' | 'closed';

/**
 * Wrapper for the Sentry client. The wrapper allows calling enable()
 * and disable() in any sequence and internally tracks the state of the
 * client. Internally, the Sentry client is initialized or closed
 * at most once.
 */
class SentryClient {
  private readonly vueApp: App;
  private state: SentryState = 'not-initialized';
  private uid: string | null = null;

  constructor(vueApp: App) {
    this.vueApp = vueApp;
  }

  /**
   * Make the Sentry client active if possible.
   *
   * @return True if the client is now enabled, false if it could not be enabled.
   */
  enable(): boolean {
    switch (this.state) {
      case 'initialized':
        return true; // No need to do anything
      case 'closed':
        return false; // Cannot activate twice
    }

    const {
      sentryEnvironment,
      sentryDsn,
      sentryEnableDebug,
      version,
      buildNumber,
    } = useRuntimeConfig().public;

    Sentry.init({
      app: [this.vueApp],
      release: `members-client@${version}+${buildNumber}`,
      dsn: String(sentryDsn),
      debug: !!sentryEnableDebug || false,
      tracesSampleRate: 0.2,
      integrations: [Sentry.browserTracingIntegration()],
      // In cloud the environment is same as project-id, we remove here the ruokaboksi- prepend from it, so ruokaboksi-dev becomes "dev"
      environment: sentryEnvironment
        ? String(sentryEnvironment).replace('ruokaboksi-', '')
        : 'local',
    });

    this.state = 'initialized';

    if (this.uid !== null) {
      Sentry.setUser({ id: this.uid });
    }

    return true;
  }

  /**
   * Make the Sentry client inactive.
   */
  async disable(): Promise<void> {
    if (this.state !== 'initialized') {
      return; // Either not initialized or already closed
    }

    Sentry.setUser(null);

    await Sentry.close();
    this.state = 'closed';
  }

  setUser(uid: string | null) {
    this.uid = uid;
    if (this.state === 'initialized') {
      if (uid !== null) {
        Sentry.setUser({ id: uid });
      } else {
        Sentry.setUser(null);
      }
    }
  }
}
