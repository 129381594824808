import { allCountries, defaultCountry } from '@ruokaboksi/api-client';

export function useCurrentCountry() {
  const country = ref(defaultCountry);
  const { subscription } = useCustomerSubscriptions();
  const url = useRequestURL();

  if (subscription.value?.deliveryAddress?.country) {
    country.value = subscription.value.deliveryAddress.country;
  } else if (url.hostname.includes('yummybox.cz')) {
    country.value = 'CZ';
  } else if (url.hostname.includes('yummybox.ee')) {
    country.value = 'EE';
  } else if (url.hostname.includes('ruokaboksi.fi')) {
    country.value = 'FI';
  }

  const countryProperties =
    allCountries.find((c) => c.countryCode === country.value) ??
    allCountries[0];

  return { country, countryProperties };
}
