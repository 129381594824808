
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexkkNR9s8A6fXI6chjuGu5OzWSd6yBWRwU1rmyTJ2BaXoMeta } from "/app/clients/members/pages/additional-products/index.tsx?macro=true";
import { default as index30lZK9zq1_4yDbJPaO9WOM_45Nm92Ag_45AuhjFTOtqXI3wMeta } from "/app/clients/members/pages/address/index.tsx?macro=true";
import { default as indexuTBfPraQ_isAy31YF3A40_45la8G2ZPPm0BEEIjzoJL88Meta } from "/app/clients/members/pages/auth-action/index.tsx?macro=true";
import { default as indexydC7hqF_453E3aCPgyJ_459_deL7fnFh5sFUgZUYtJLqZPIMeta } from "/app/clients/members/pages/delivery-calendar/index.tsx?macro=true";
import { default as indexwcZ9b6rn39Ajs4TtxG2os_3g6SJC7dH1XcGuQShXEz4Meta } from "/app/clients/members/pages/existing-user/index.tsx?macro=true";
import { default as index_A2vkIVdNtz4iXB1TNzpiEGhMSY3pl7xWzdVPq9S_9MMeta } from "/app/clients/members/pages/index.tsx?macro=true";
import { default as index1kOauA0YXjAlGVgqU1y_6M5T6TlijvZeHODyxb1qjlgMeta } from "/app/clients/members/pages/more/account/index.tsx?macro=true";
import { default as index5SweS4r0SuoA3Rjy_TMAR5HJL3C3v_454jxdcZ1o7NG1AMeta } from "/app/clients/members/pages/more/change-language/index.tsx?macro=true";
import { default as index7FQEdsntW6MugBeJ2Mkd24fxTEZWVa8ozmAujOXXEpEMeta } from "/app/clients/members/pages/more/change-password/index.tsx?macro=true";
import { default as indexNfsO88yF_l2AsBm6OcayjyvrXfyeaVhB8SCJhKvzGl4Meta } from "/app/clients/members/pages/more/contact-details/index.tsx?macro=true";
import { default as _91id_93PrV2sL8pemWi4OX_kaAsJARbino5XyymlFsSHzod5W0Meta } from "/app/clients/members/pages/more/deliveries/[id].tsx?macro=true";
import { default as indexLXdlrjrAJDAu_45sl3hXmT2Ns60JX_45JCiBU1hlg56_MO8Meta } from "/app/clients/members/pages/more/deliveries/index.tsx?macro=true";
import { default as indexJjBHHv8JKWcZl_On5yCqoBNFAc5_9ozRwkptmYyeoa0Meta } from "/app/clients/members/pages/more/index.tsx?macro=true";
import { default as indexchhmt2a7N1rXszoyhNdufNDHS4dbLNGfttNqILiWD_45wMeta } from "/app/clients/members/pages/more/notifications/index.tsx?macro=true";
import { default as indexDT_jb3a04Vd4hahTqtFgOl9FrbV0Lhha_45mxCXxpi42UMeta } from "/app/clients/members/pages/more/offers/credits/index.tsx?macro=true";
import { default as indexsh6coXxKnYdk3q68UGmRIEZQiQYfvdlOz0uA4Vd_45G8MMeta } from "/app/clients/members/pages/more/offers/index.tsx?macro=true";
import { default as indexDuZcUUkDaBhIAF5i9jISmqhvnwHqBi7vLrrKV5ldlt4Meta } from "/app/clients/members/pages/more/offers/loyalty/index.tsx?macro=true";
import { default as indexI_YOewlmxhn7Fe9_45P0mJUEuijvEwQSM7qZGUFO4WBV0Meta } from "/app/clients/members/pages/more/offers/referral/index.tsx?macro=true";
import { default as addlqf9NXOgUQlggcrPejYIH3GAEO_L16lIu251X3f6kfgMeta } from "/app/clients/members/pages/more/payment-methods/add.tsx?macro=true";
import { default as indexmFqo_45ky3uLgcVrLdyPBbWd7K89SN_5nCuXWp1L5f87cMeta } from "/app/clients/members/pages/more/payment-methods/index.tsx?macro=true";
import { default as indexAbB_9kjYgAtUegONCpQ2xRjNuR1G_45exNwli7iuygg4UMeta } from "/app/clients/members/pages/more/settings/index.tsx?macro=true";
import { default as indexfLkafZM6dFX3CIAw_NRf7VYsN4qH046ro7yWiX_4510IQMeta } from "/app/clients/members/pages/more/subscription/index.tsx?macro=true";
import { default as indexEn33ogUODnlH72_45wl4B_4540yslQ8S3StSm_MHlqPl9ocMeta } from "/app/clients/members/pages/more/support/index.tsx?macro=true";
import { default as indexWJKhIbb8o3_6Wi5TYDoke08wLlkQAdT6BxqiMVLkZKAMeta } from "/app/clients/members/pages/new-password/index.tsx?macro=true";
import { default as indexzrO03zOnwIkcGUlJT0aGgUVWCcEIS1vK2AsbzasV42AMeta } from "/app/clients/members/pages/onboarding/add-payment-method/index.tsx?macro=true";
import { default as indexXWHvFJ2DuZferDqfu1Do7oA5VzSU0YuJP_45AceJ0L8f4Meta } from "/app/clients/members/pages/onboarding/confirm-address/index.tsx?macro=true";
import { default as indexqtqxT0L4ksOJ_jQ7Z_45XMi3vvQcGefFfPU1en79wdVfsMeta } from "/app/clients/members/pages/onboarding/index.tsx?macro=true";
import { default as indextpZohe0oxnYH5nS8ZwPVpQILkDXrpg5Rz2v95_n0mM0Meta } from "/app/clients/members/pages/onboarding/settings/index.tsx?macro=true";
import { default as indexuZ_N_45xGu69uSVQsi_9Zh7Hlq3psnYToWtZeSikGsCMAMeta } from "/app/clients/members/pages/pay-delivery/index.tsx?macro=true";
import { default as indexjTe1vmfRx1fjsijY4l_JauP9whI3QKJAao_aIv5eJkYMeta } from "/app/clients/members/pages/payment-required/index.tsx?macro=true";
import { default as indexWK_Fp9xmcLMDKakg8fEF1tUNMecKeRut_qtG3gIgCcMMeta } from "/app/clients/members/pages/payment/index.tsx?macro=true";
import { default as indexZb7b9U50Y4t_zhm14Nsv6KnXj4r4eUnV_45lk_45H0UIfVwMeta } from "/app/clients/members/pages/postal-code/index.tsx?macro=true";
import { default as index9kQGnuc56ikmwLYsBX4hmiJUJRBak4o2lxfWS7fWgEYMeta } from "/app/clients/members/pages/recipes/index.tsx?macro=true";
import { default as index3F_g9Mc_PbK3UdNJtOY_YEHOc92WQkY2ypW_sp3v5tUMeta } from "/app/clients/members/pages/register/index.tsx?macro=true";
import { default as index2eQfzJ42g_0iyXJyJ9_45XpZrqRVaWxsim75wqUN3wiSIMeta } from "/app/clients/members/pages/reset-password/index.tsx?macro=true";
import { default as indexDBXVRLLIu3Sx3jU_WtLk3bPHR2fclRDcWbVlTq9_45q_454Meta } from "/app/clients/members/pages/sign-in-email/index.tsx?macro=true";
import { default as indexENtnBmUeBYRcrECzTXhf3Qg_4fAtWI_452_KWIOV88zXMMeta } from "/app/clients/members/pages/sign-in-password/index.tsx?macro=true";
import { default as indexkMt2HKud6_qFOciF4w_45zn7ejKsAHmQhkKH6iHfO_457CQMeta } from "/app/clients/members/pages/sign-in-send-link/index.tsx?macro=true";
import { default as indexnVkhULdtXWgJC4XpW6U7MELzIuN1jauvRnc_3lOw_45MEMeta } from "/app/clients/members/pages/sign-in/index.tsx?macro=true";
import { default as indexuhYufhcpVRTK0ZWaA7O4lhwURtpdrpHTkAScgvq9ZGwMeta } from "/app/clients/members/pages/sign-out/index.tsx?macro=true";
import { default as index5cIvaCSlmEjTaRiiFkYnxhb3_PMX7xqzQQwLPJphIKMMeta } from "/app/clients/members/pages/store/index.tsx?macro=true";
export default [
  {
    name: "additional-products",
    path: "/additional-products",
    meta: indexkkNR9s8A6fXI6chjuGu5OzWSd6yBWRwU1rmyTJ2BaXoMeta || {},
    component: () => import("/app/clients/members/pages/additional-products/index.tsx")
  },
  {
    name: "address",
    path: "/address",
    meta: index30lZK9zq1_4yDbJPaO9WOM_45Nm92Ag_45AuhjFTOtqXI3wMeta || {},
    component: () => import("/app/clients/members/pages/address/index.tsx")
  },
  {
    name: "auth-action",
    path: "/auth-action",
    meta: indexuTBfPraQ_isAy31YF3A40_45la8G2ZPPm0BEEIjzoJL88Meta || {},
    component: () => import("/app/clients/members/pages/auth-action/index.tsx")
  },
  {
    name: "delivery-calendar",
    path: "/delivery-calendar",
    meta: indexydC7hqF_453E3aCPgyJ_459_deL7fnFh5sFUgZUYtJLqZPIMeta || {},
    component: () => import("/app/clients/members/pages/delivery-calendar/index.tsx")
  },
  {
    name: "existing-user",
    path: "/existing-user",
    meta: indexwcZ9b6rn39Ajs4TtxG2os_3g6SJC7dH1XcGuQShXEz4Meta || {},
    component: () => import("/app/clients/members/pages/existing-user/index.tsx")
  },
  {
    name: "index",
    path: "/",
    meta: index_A2vkIVdNtz4iXB1TNzpiEGhMSY3pl7xWzdVPq9S_9MMeta || {},
    component: () => import("/app/clients/members/pages/index.tsx")
  },
  {
    name: "more-account",
    path: "/more/account",
    meta: index1kOauA0YXjAlGVgqU1y_6M5T6TlijvZeHODyxb1qjlgMeta || {},
    component: () => import("/app/clients/members/pages/more/account/index.tsx")
  },
  {
    name: "more-change-language",
    path: "/more/change-language",
    meta: index5SweS4r0SuoA3Rjy_TMAR5HJL3C3v_454jxdcZ1o7NG1AMeta || {},
    component: () => import("/app/clients/members/pages/more/change-language/index.tsx")
  },
  {
    name: "more-change-password",
    path: "/more/change-password",
    meta: index7FQEdsntW6MugBeJ2Mkd24fxTEZWVa8ozmAujOXXEpEMeta || {},
    component: () => import("/app/clients/members/pages/more/change-password/index.tsx")
  },
  {
    name: "more-contact-details",
    path: "/more/contact-details",
    meta: indexNfsO88yF_l2AsBm6OcayjyvrXfyeaVhB8SCJhKvzGl4Meta || {},
    component: () => import("/app/clients/members/pages/more/contact-details/index.tsx")
  },
  {
    name: "more-deliveries-id",
    path: "/more/deliveries/:id()",
    meta: _91id_93PrV2sL8pemWi4OX_kaAsJARbino5XyymlFsSHzod5W0Meta || {},
    component: () => import("/app/clients/members/pages/more/deliveries/[id].tsx")
  },
  {
    name: "more-deliveries",
    path: "/more/deliveries",
    meta: indexLXdlrjrAJDAu_45sl3hXmT2Ns60JX_45JCiBU1hlg56_MO8Meta || {},
    component: () => import("/app/clients/members/pages/more/deliveries/index.tsx")
  },
  {
    name: "more",
    path: "/more",
    meta: indexJjBHHv8JKWcZl_On5yCqoBNFAc5_9ozRwkptmYyeoa0Meta || {},
    component: () => import("/app/clients/members/pages/more/index.tsx")
  },
  {
    name: "more-notifications",
    path: "/more/notifications",
    meta: indexchhmt2a7N1rXszoyhNdufNDHS4dbLNGfttNqILiWD_45wMeta || {},
    component: () => import("/app/clients/members/pages/more/notifications/index.tsx")
  },
  {
    name: "more-offers-credits",
    path: "/more/offers/credits",
    meta: indexDT_jb3a04Vd4hahTqtFgOl9FrbV0Lhha_45mxCXxpi42UMeta || {},
    component: () => import("/app/clients/members/pages/more/offers/credits/index.tsx")
  },
  {
    name: "more-offers",
    path: "/more/offers",
    meta: indexsh6coXxKnYdk3q68UGmRIEZQiQYfvdlOz0uA4Vd_45G8MMeta || {},
    component: () => import("/app/clients/members/pages/more/offers/index.tsx")
  },
  {
    name: "more-offers-loyalty",
    path: "/more/offers/loyalty",
    meta: indexDuZcUUkDaBhIAF5i9jISmqhvnwHqBi7vLrrKV5ldlt4Meta || {},
    component: () => import("/app/clients/members/pages/more/offers/loyalty/index.tsx")
  },
  {
    name: "more-offers-referral",
    path: "/more/offers/referral",
    meta: indexI_YOewlmxhn7Fe9_45P0mJUEuijvEwQSM7qZGUFO4WBV0Meta || {},
    component: () => import("/app/clients/members/pages/more/offers/referral/index.tsx")
  },
  {
    name: "more-payment-methods-add",
    path: "/more/payment-methods/add",
    meta: addlqf9NXOgUQlggcrPejYIH3GAEO_L16lIu251X3f6kfgMeta || {},
    component: () => import("/app/clients/members/pages/more/payment-methods/add.tsx")
  },
  {
    name: "more-payment-methods",
    path: "/more/payment-methods",
    meta: indexmFqo_45ky3uLgcVrLdyPBbWd7K89SN_5nCuXWp1L5f87cMeta || {},
    component: () => import("/app/clients/members/pages/more/payment-methods/index.tsx")
  },
  {
    name: "more-settings",
    path: "/more/settings",
    meta: indexAbB_9kjYgAtUegONCpQ2xRjNuR1G_45exNwli7iuygg4UMeta || {},
    component: () => import("/app/clients/members/pages/more/settings/index.tsx")
  },
  {
    name: "more-subscription",
    path: "/more/subscription",
    meta: indexfLkafZM6dFX3CIAw_NRf7VYsN4qH046ro7yWiX_4510IQMeta || {},
    component: () => import("/app/clients/members/pages/more/subscription/index.tsx")
  },
  {
    name: "more-support",
    path: "/more/support",
    meta: indexEn33ogUODnlH72_45wl4B_4540yslQ8S3StSm_MHlqPl9ocMeta || {},
    component: () => import("/app/clients/members/pages/more/support/index.tsx")
  },
  {
    name: "new-password",
    path: "/new-password",
    meta: indexWJKhIbb8o3_6Wi5TYDoke08wLlkQAdT6BxqiMVLkZKAMeta || {},
    component: () => import("/app/clients/members/pages/new-password/index.tsx")
  },
  {
    name: "onboarding-add-payment-method",
    path: "/onboarding/add-payment-method",
    meta: indexzrO03zOnwIkcGUlJT0aGgUVWCcEIS1vK2AsbzasV42AMeta || {},
    component: () => import("/app/clients/members/pages/onboarding/add-payment-method/index.tsx")
  },
  {
    name: "onboarding-confirm-address",
    path: "/onboarding/confirm-address",
    meta: indexXWHvFJ2DuZferDqfu1Do7oA5VzSU0YuJP_45AceJ0L8f4Meta || {},
    component: () => import("/app/clients/members/pages/onboarding/confirm-address/index.tsx")
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: indexqtqxT0L4ksOJ_jQ7Z_45XMi3vvQcGefFfPU1en79wdVfsMeta || {},
    component: () => import("/app/clients/members/pages/onboarding/index.tsx")
  },
  {
    name: "onboarding-settings",
    path: "/onboarding/settings",
    meta: indextpZohe0oxnYH5nS8ZwPVpQILkDXrpg5Rz2v95_n0mM0Meta || {},
    component: () => import("/app/clients/members/pages/onboarding/settings/index.tsx")
  },
  {
    name: "pay-delivery",
    path: "/pay-delivery",
    meta: indexuZ_N_45xGu69uSVQsi_9Zh7Hlq3psnYToWtZeSikGsCMAMeta || {},
    component: () => import("/app/clients/members/pages/pay-delivery/index.tsx")
  },
  {
    name: "payment-required",
    path: "/payment-required",
    meta: indexjTe1vmfRx1fjsijY4l_JauP9whI3QKJAao_aIv5eJkYMeta || {},
    component: () => import("/app/clients/members/pages/payment-required/index.tsx")
  },
  {
    name: "payment",
    path: "/payment",
    meta: indexWK_Fp9xmcLMDKakg8fEF1tUNMecKeRut_qtG3gIgCcMMeta || {},
    component: () => import("/app/clients/members/pages/payment/index.tsx")
  },
  {
    name: "postal-code",
    path: "/postal-code",
    meta: indexZb7b9U50Y4t_zhm14Nsv6KnXj4r4eUnV_45lk_45H0UIfVwMeta || {},
    component: () => import("/app/clients/members/pages/postal-code/index.tsx")
  },
  {
    name: "recipes",
    path: "/recipes",
    meta: index9kQGnuc56ikmwLYsBX4hmiJUJRBak4o2lxfWS7fWgEYMeta || {},
    component: () => import("/app/clients/members/pages/recipes/index.tsx")
  },
  {
    name: "register",
    path: "/register",
    meta: index3F_g9Mc_PbK3UdNJtOY_YEHOc92WQkY2ypW_sp3v5tUMeta || {},
    component: () => import("/app/clients/members/pages/register/index.tsx")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: index2eQfzJ42g_0iyXJyJ9_45XpZrqRVaWxsim75wqUN3wiSIMeta || {},
    component: () => import("/app/clients/members/pages/reset-password/index.tsx")
  },
  {
    name: "sign-in-email",
    path: "/sign-in-email",
    meta: indexDBXVRLLIu3Sx3jU_WtLk3bPHR2fclRDcWbVlTq9_45q_454Meta || {},
    component: () => import("/app/clients/members/pages/sign-in-email/index.tsx")
  },
  {
    name: "sign-in-password",
    path: "/sign-in-password",
    meta: indexENtnBmUeBYRcrECzTXhf3Qg_4fAtWI_452_KWIOV88zXMMeta || {},
    component: () => import("/app/clients/members/pages/sign-in-password/index.tsx")
  },
  {
    name: "sign-in-send-link",
    path: "/sign-in-send-link",
    meta: indexkMt2HKud6_qFOciF4w_45zn7ejKsAHmQhkKH6iHfO_457CQMeta || {},
    component: () => import("/app/clients/members/pages/sign-in-send-link/index.tsx")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexnVkhULdtXWgJC4XpW6U7MELzIuN1jauvRnc_3lOw_45MEMeta || {},
    component: () => import("/app/clients/members/pages/sign-in/index.tsx")
  },
  {
    name: "sign-out",
    path: "/sign-out",
    meta: indexuhYufhcpVRTK0ZWaA7O4lhwURtpdrpHTkAScgvq9ZGwMeta || {},
    component: () => import("/app/clients/members/pages/sign-out/index.tsx")
  },
  {
    name: "store",
    path: "/store",
    meta: index5cIvaCSlmEjTaRiiFkYnxhb3_PMX7xqzQQwLPJphIKMMeta || {},
    component: () => import("/app/clients/members/pages/store/index.tsx")
  }
]