import Icon123 from '@/assets/icons/icon-123.svg?component';
import IconChartPie from '@/assets/icons/icon-chart-pie-r.svg?component';
import ChevronOpen from '@/assets/icons/icon-chevron-down.svg?component';
import ChevronClose from '@/assets/icons/icon-chevron-up.svg?component';
import IconClockHistory from '@/assets/icons/icon-clock-history.svg?component';
import IconClose from '@/assets/icons/icon-close.svg?component';
import IconDinner from '@/assets/icons/icon-dinner-dining.svg?component';
import IconHeart from '@/assets/icons/icon-heart.svg?component';
import IconPeople from '@/assets/icons/icon-people.svg?component';
import IconTips from '@/assets/icons/icon-tips-and-updates.svg?component';
import Button from '@/components/Button';
import Details from '@/components/Details';
import ProductPrice from '@/components/ProductPrice';
import SanityPicture from '@/components/SanityPicture';
import { PortableText } from '@portabletext/vue';
import type { Recipe, RecipeInstructions } from '@ruokaboksi/api-client';
import { storeToRefs } from 'pinia';
import type { VNode } from 'vue';
import IngredientLists from './IngredientLists';
import NutritionInfo from './NutritionInfo';
import './ProductModal.css';
import {
  closeModalOnBackdrop,
  closeModalOnClick,
  closeModalOnEscape,
  requiredImageFields,
} from './utils';

export default defineComponent({
  name: 'ProductModal',
  setup() {
    const { getRecipeInstructions } = useProductsApi();
    const modalStore = useModalStore();
    const { product } = storeToRefs(modalStore);
    const currentProduct = computed(() => product.value?.product);
    const id = computed(() => currentProduct.value?.id);
    const isProductModalVisible = modalStore.isProductModalVisible;

    const isRecipe = computed<boolean>(
      () => !!(currentProduct.value && 'allergens' in currentProduct.value)
    );

    const recipeInstructions =
      currentProduct.value && isRecipe.value
        ? getRecipeInstructions(id.value!)
        : null;

    const { t, localeProperties } = useI18n();

    const placeholderImage = computed(() =>
      getPlaceholderCardImage(localeProperties.value.brand)
    );

    /**
     * ID of modal window referenced by caller element using `aria-controls`
     * @link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-controls
     */
    const modalId =
      id.value ||
      (currentProduct.value ? `product-modal-${id.value}` : undefined);

    /** Title for the close button. */
    const closeButtonTitle = computed<string>(() => {
      if (!currentProduct.value) return t('product_modal.close_button_title');
      if (isRecipe.value) {
        return `$t('product_modal.close_button_additional_recipe_info_title') ${currentProduct.value.title}`;
      }
      return `$t('product_modal.close_button_additional_additional_product_info_title') ${currentProduct.value.description}`;
    });

    const labelsWithColors = computed<Recipe['recipeLabels']>(() => {
      if (!currentProduct.value || !isRecipe.value) return [];

      if ('recipeLabels' in currentProduct.value) {
        const recipeProduct = currentProduct.value as Recipe;
        return recipeProduct.recipeLabels.filter(
          (r) => r.backgroundColor && r.textColor && r.title
        );
      }

      return [];
    });

    /**
     * An array of labels containing an image and title
     * used for displaying badges under the main image.
     */
    const imageLabels = computed<
      Recipe['allergens'] | Recipe['diets'] | Recipe['recipeLabels']
    >(() => {
      if (!isRecipe.value) return [];
      const p = currentProduct.value as Recipe;
      const allergens = p.allergens.length
        ? p.allergens.filter(requiredImageFields)
        : [];
      const diets = p.diets.length ? p.diets.filter(requiredImageFields) : [];
      const recipeLabels = p.recipeLabels.length
        ? p.recipeLabels.filter(requiredImageFields)
        : [];
      return [...allergens, ...diets, ...recipeLabels];
    });

    /** Preparation time as a string containing long suffix. */
    const preparationTimeWithSuffix = computed<string>(() =>
      currentProduct.value &&
      'preparationTime' in currentProduct.value &&
      currentProduct.value.preparationTime
        ? t('product_modal.minutes', {
            minutes: currentProduct.value.preparationTime,
          })
        : ''
    );

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', closeModalOnEscape);
      window.document.documentElement.dataset.modalVisible = 'false';
    });

    onMounted(() => {
      window.addEventListener('keydown', closeModalOnEscape);
      window.document.documentElement.dataset.modalVisible = 'true';
    });

    return {
      closeButtonTitle,
      imageLabels,
      isProductModalVisible,
      labelsWithColors,
      modalId,
      preparationTimeWithSuffix,
      product,
      recipeInstructions,
      placeholderImage,
    };
  },
  render(): VNode | null {
    if (!this.isProductModalVisible || !this.product) return null;
    const product = this.product.product as Recipe;
    const recipeInstructions = this.recipeInstructions?.data.value as
      | RecipeInstructions
      | undefined;
    return (
      <aside
        class="product-modal"
        id={this.modalId}
        onClick={closeModalOnBackdrop}
      >
        <div class="product-modal-wrapper">
          <header class="product-modal-header">
            <div class="product-modal-container title-container order-4">
              <h2 class="product-modal-title">{product.title}</h2>
              {this.product.product && (
                <ProductPrice product={this.product.product} />
              )}
            </div>

            <div class="product-modal-image order-1">
              <SanityPicture
                alt={product.mainImage?.alt || product.title}
                src={
                  product.mainImage?.sizes?.large ??
                  product.plateImage?.sizes?.large ??
                  this.placeholderImage
                }
              />
            </div>

            {this.labelsWithColors.length
              ? this.labelsWithColors.map((r) => (
                  <div
                    class="order-2 items-center py-1 text-center font-bold uppercase"
                    style={{
                      backgroundColor: r.backgroundColor || undefined,
                      color: r.textColor || undefined,
                    }}
                  >
                    {formatTitle(r.title)}
                  </div>
                ))
              : null}
            {this.imageLabels.length ? (
              <dl class="dietary-labels order-3">
                <dt class="sr-only">
                  {this.$t('product_modal.dietary_labels')}
                </dt>
                {this.imageLabels.map(
                  (label, index) =>
                    label?.image?.sizes && (
                      <dd class="product-modal-label">
                        <SanityPicture
                          alt={label.image.alt || label.title}
                          class="icon"
                          key={index}
                          src={label.image.sizes?.thumbnail}
                        />
                        {label.title}
                      </dd>
                    )
                )}
              </dl>
            ) : null}
          </header>
          <div class="product-modal-content">
            <div class="product-modal-container">
              {product.description ? (
                <div class="product-modal-description">
                  <PortableText value={product.description} />
                </div>
              ) : null}
              {product.serves ? (
                <dl class="product-modal-stats">
                  <dt class="sr-only">{this.$t('product_modal.portions')}</dt>
                  <dd class="icon-label">
                    <IconPeople aria-hidden="true" class="title-icon" />
                    {this.$t('product_modal.how_many_portions', {
                      servingPortions: product.serves.toString(),
                    })}
                  </dd>
                </dl>
              ) : null}
              {this.preparationTimeWithSuffix ? (
                <dl class="product-modal-stats">
                  <dt class="sr-only">{this.$t('product_modal.preptime')}</dt>
                  <dd class="icon-label">
                    <IconClockHistory aria-hidden="true" class="title-icon" />
                    {this.preparationTimeWithSuffix}
                  </dd>
                </dl>
              ) : null}
              {recipeInstructions?.nutritionInfo ? (
                <Details
                  v-slots={{
                    summary: () => (
                      <summary>
                        <span class="icon-label">
                          <Icon123 aria-hidden="true" class="title-icon" />
                          <span class="icon-label-text">
                            <span>
                              {this.$t('product_modal.nutrition_info_heading')}
                            </span>
                          </span>
                        </span>
                        <ChevronOpen
                          aria-hidden="true"
                          class="icon icon-open"
                        />
                        <ChevronClose
                          aria-hidden="true"
                          class="icon icon-close"
                        />
                      </summary>
                    ),
                  }}
                >
                  <NutritionInfo
                    nutritionInfo={recipeInstructions.nutritionInfo}
                  />
                </Details>
              ) : null}
              {recipeInstructions?.ingredientLists?.length ? (
                <Details
                  v-slots={{
                    summary: () => (
                      <summary>
                        <span class="icon-label">
                          <IconChartPie aria-hidden="true" class="title-icon" />
                          {this.$t('product_modal.ingredients')}
                        </span>
                        <ChevronOpen
                          aria-hidden="true"
                          class="icon icon-open"
                        />
                        <ChevronClose
                          aria-hidden="true"
                          class="icon icon-close"
                        />
                      </summary>
                    ),
                  }}
                >
                  <IngredientLists
                    ingredientLists={recipeInstructions.ingredientLists}
                  />
                  <div class="border-t-1 ml-9 mt-3 flex flex-col gap-1 pt-3 empty:hidden">
                    {recipeInstructions.ingredientLists.flatMap(
                      (ingredientList) =>
                        ingredientList.ingredients.map(
                          (ingredient) =>
                            ingredient.productInformation && (
                              <div class="w-auto text-xs [&>p]:font-light">
                                <PortableText
                                  value={ingredient.productInformation}
                                />
                              </div>
                            )
                        )
                    )}
                  </div>
                </Details>
              ) : null}
              {recipeInstructions?.pantryItems.length ? (
                <Details
                  v-slots={{
                    summary: () => (
                      <summary>
                        <span class="icon-label">
                          <IconHeart aria-hidden="true" class="title-icon" />
                          {this.$t('product_modal.pantry_items')}
                        </span>
                        <ChevronOpen
                          aria-hidden="true"
                          class="icon icon-open"
                        />
                        <ChevronClose
                          aria-hidden="true"
                          class="icon icon-close"
                        />
                      </summary>
                    ),
                  }}
                >
                  <ul class="pantry-items dot-list">
                    {recipeInstructions.pantryItems.map((item) => (
                      <li>{item.title.trim()}</li>
                    ))}
                  </ul>
                </Details>
              ) : null}
              {recipeInstructions?.steps ? (
                <Details
                  open
                  v-slots={{
                    summary: () => (
                      <summary>
                        <span class="icon-label">
                          <IconDinner aria-hidden="true" class="title-icon" />
                          {this.$t('product_modal.recipe_instructions')}
                        </span>
                        <ChevronOpen
                          aria-hidden="true"
                          class="icon icon-open"
                        />
                        <ChevronClose
                          aria-hidden="true"
                          class="icon icon-close"
                        />
                      </summary>
                    ),
                  }}
                >
                  {recipeInstructions?.tips.length ? (
                    <div class="recipe-tips">
                      <span class="icon-label">
                        <IconTips aria-hidden="true" class="title-icon" />
                        <span class="text-base">
                          {this.$t('product_modal.tip')}
                        </span>
                      </span>
                      <div class="tips">
                        {recipeInstructions.tips.map(
                          (tip) => tip && <PortableText value={tip} />
                        )}
                      </div>
                    </div>
                  ) : null}
                  {recipeInstructions.steps ? (
                    <div class="recipe-steps">
                      <PortableText value={recipeInstructions.steps} />
                    </div>
                  ) : null}
                </Details>
              ) : null}
            </div>
          </div>
          <footer>
            <Button
              class="button-text close-modal"
              onClick={closeModalOnClick}
              title={this.closeButtonTitle}
            >
              <IconClose class="icon" />
            </Button>
          </footer>
        </div>
      </aside>
    );
  },
});
