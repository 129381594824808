import type { Country } from '@ruokaboksi/api-client';

declare global {
  interface Window {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    lsq: any;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    _lsq: any;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    tmary: any;
  }
}

export const ECOM_PAGES = ['/store', '/register', '/address', '/payment'];

export default function useTracklution() {
  const initTracklution = () => {
    const { country } = useCurrentCountry();

    const {
      tracklutionContainerDomainFi,
      tracklutionContainerDomainCz,
      tracklutionContainerDomainEe,
      tracklutionContainerIdFi,
      tracklutionContainerIdCz,
      tracklutionContainerIdEe,
    } = useRuntimeConfig().public;

    const TracklutionContainerMap: Record<
      Country,
      { id: string; domain: string }
    > = {
      FI: {
        id: tracklutionContainerIdFi,
        domain: tracklutionContainerDomainFi,
      },
      CZ: {
        id: tracklutionContainerIdCz,
        domain: tracklutionContainerDomainCz,
      },
      EE: {
        id: tracklutionContainerIdEe,
        domain: tracklutionContainerDomainEe,
      },
    };

    const containerDomain = TracklutionContainerMap[country.value].domain;
    const containerId = TracklutionContainerMap[country.value].id;

    const f = window;
    const b = document;
    const e = 'script';
    let v = `${containerDomain}/js/tracking.js?version=2024-07-08`;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    let n: any = undefined;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    let t: any = undefined;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    let s: any = undefined;
    if (f.lsq) return;
    n = f.lsq = function () {
      // biome-ignore lint/style/noArguments: <explanation>
      n.callMethod ? n.callMethod(arguments) : n.queue.push(arguments);
    };
    if (!f._lsq) f._lsq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '1.0';
    n.src = v;
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
    t.onerror = function () {
      v = 'https://tracking.leadspark.io/js/tracking.js?version=2024-04-15';
      f._lsq!.src = v;
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s.parentNode.insertBefore(t, s);
    };
    if (window.lsq) {
      const lsq = window.lsq;
      lsq('init', containerId);
    }
  };

  const sendEvent = (
    event: TrackingEvent,
    payload?: Record<string, string>
  ) => {
    if (window.lsq) {
      const lsq = window.lsq;
      lsq('track', event, payload);
    }
  };

  return {
    initTracklution,
    sendEvent,
  };
}

export enum TrackingEvent {
  ViewContent = 'ViewContent',
  AddToCart = 'AddToCart',
  InitiateCheckout = 'InitiateCheckout',
  ContactInfo = 'ContactInfo',
  AddPaymentInfo = 'AddPaymentInfo',
}
