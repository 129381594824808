/** Router paths that do not require a user. */
export const USER_REQUIRED_WHITELIST = [
  '/auth-action',
  '/new-password',
  '/reset-password',
  '/sign-in',
  '/sign-in-email',
  '/sign-in-password',
  '/sign-in-send-link',
  '/sign-out',
  '/register',
  '/store',
  '/postal-code',
];
