import { clearConsentCookie } from '@/composables/useTrackingConsent';
import type { CustomerFormData, TrackingConsent } from '@/models';
import type {
  Customer,
  DraftableCustomer,
  DraftableCustomerUpdate,
  ImportSingleInput,
  ImportSingleOutput,
  UTMTags,
} from '@ruokaboksi/api-client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import type { Ref } from 'vue';

export const KEY_MY_CUSTOMER_INFO = 'myCustomerInfo';
export const KEY_MY_CUSTOMER_STATE = 'myCustomerState';
export const KEY_MY_CUSTOMER_LANGUAGE = 'myCustomerLanguage';
export const KEY_MY_ZENDESK_TOKEN = 'myZendeskToken';
export const KEY_MY_MAILING_LISTS = 'myMailingLists';
export const KEY_MY_REFERRAL_CODE = 'myReferralCode';

/**
 * Composable for fetching information from the Customers service.
 */
export default function useCustomerApi() {
  const { isLoggedIn } = useCurrentUser();
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const { locale: localeCookie } = useLocale();
  const { market } = useCurrentMarket();

  const getMyCustomerInfo = () => {
    const queryFn = async () => {
      const customer = await apiClient.customers.retrieve({
        customerId: '_me',
      });

      if (customer.language && customer.language !== localeCookie.value) {
        localeCookie.value = customer.language;
      }
      if (
        customer.trackingConsentAnalytics != null ||
        customer.trackingConsentMarketing != null ||
        customer.trackingConsentPreferences != null
      ) {
        const customerCookies: TrackingConsent = {
          trackingConsentAnalytics: customer.trackingConsentAnalytics,
          trackingConsentMarketing: customer.trackingConsentMarketing,
          trackingConsentPreferences: customer.trackingConsentPreferences,
        };
        saveConsentCookies(customerCookies);
      }

      return customer;
    };
    const queryKey = [KEY_MY_CUSTOMER_INFO];

    return useQuery({
      queryKey,
      queryFn,
      enabled: isLoggedIn,
      retry: 3,
      staleTime: 30 * 1000,
    });
  };

  const getMyCustomerState = (
    refetchInterval: Ref<number | false> = ref(false)
  ) => {
    const queryFn = async () => {
      return await apiClient.customers.retrieveState({
        customerId: '_me',
      });
    };
    const queryKey = [KEY_MY_CUSTOMER_STATE];
    return useQuery({
      queryKey,
      queryFn,
      enabled: isLoggedIn,
      staleTime: 30 * 1000,
      refetchInterval,
    });
  };

  const invalidateCustomerState = (): Promise<void> => {
    return queryClient.invalidateQueries({ queryKey: [KEY_MY_CUSTOMER_STATE] });
  };

  const registerCustomer = async (
    customerInfo: CustomerFormData
  ): Promise<Customer> => {
    const response = await apiClient.customers.register({
      customerId: '_me',
      data: customerInfo,
    });
    queryClient.invalidateQueries({ queryKey: [KEY_MY_CUSTOMER_INFO] });
    queryClient.invalidateQueries({ queryKey: [KEY_MY_CUSTOMER_STATE] });
    return response;
  };

  const createDraftCustomer = async (
    email: string,
    customerData: Partial<DraftableCustomer> &
      UTMTags & { referralCode?: string }
  ): Promise<DraftableCustomer> => {
    const response = await apiClient.customers.createDraft({
      customerId: '_me',
      data: {
        ...customerData,
        email,
        firstName: customerData.firstName ?? null,
        lastName: customerData.lastName ?? null,
        isDraft: true,
        language: localeCookie.value,
        utmMedium: customerData.utmMedium,
        utmContent: customerData.utmContent,
        utmSource: customerData.utmSource,
        utmCampaign: customerData.utmCampaign,
        utmTerm: customerData.utmTerm,
        referralCode: customerData.referralCode,
      },
    });

    clearConsentCookie();

    return response;
  };

  const finaliseDraftCustomer = ({ onSuccess }: { onSuccess: () => void }) => {
    const queryFn = async (
      body: ImportSingleInput
    ): Promise<ImportSingleOutput> => {
      const response = await apiClient.customers.finalise({
        customerId: '_me',
        data: body,
      });

      await queryClient.invalidateQueries({
        queryKey: [KEY_MY_CUSTOMER_STATE],
      });
      await queryClient.invalidateQueries({
        queryKey: [KEY_DELIVERY_WEEK_CALENDAR],
      });

      return response;
    };

    return useMutation({
      mutationFn: queryFn,
      onSuccess,
    });
  };

  const setBillingAddress = async (
    body: DraftableCustomerUpdate
  ): Promise<DraftableCustomer> => {
    const response = await apiClient.customers.update({
      customerId: '_me',
      data: body,
    });

    queryClient.invalidateQueries({ queryKey: [KEY_MY_CUSTOMER_INFO] });
    return response;
  };

  const updateLanguageSelection = async (
    body: DraftableCustomerUpdate
  ): Promise<DraftableCustomer> => {
    const response = await apiClient.customers.update({
      customerId: '_me',
      data: body,
    });

    queryClient.invalidateQueries({ queryKey: [KEY_MY_CUSTOMER_LANGUAGE] });
    return response;
  };

  const getCustomerZendeskToken = () => {
    const queryFn = async () => {
      const response = await apiClient.customers.retrieveZendeskToken({
        customerId: '_me',
      });
      return response;
    };
    const queryKey = [KEY_MY_ZENDESK_TOKEN];
    return useQuery({ queryKey, queryFn, enabled: isLoggedIn });
  };

  const enrollToLoyaltyProgram = async (): Promise<Customer> => {
    const response = await apiClient.customers.update({
      customerId: '_me',
      data: { inLoyaltyProgram: true },
    });
    if (response.isDraft) {
      throw new Error('Customer is a draft, cannot enroll to loyalty program');
    }
    queryClient.invalidateQueries({ queryKey: [KEY_MY_CUSTOMER_INFO] });
    return response;
  };

  const getMailingLists = () => {
    const queryFn = async () => {
      const response =
        await apiClient.customers.retrieveMailingListsForCustomer({
          customerId: '_me',
        });

      const mailingListsByName: { [key: string]: string[] } = {};

      response.mailingLists.forEach((list) => {
        const listName = list.split('_')[1];
        if (!mailingListsByName[listName]) {
          mailingListsByName[listName] = [];
        }
        mailingListsByName[listName].push(list);
      });

      return {
        mailingLists: mailingListsByName,
        subscribedLists: response.subscribedLists,
      };
    };

    const queryKey = [KEY_MY_MAILING_LISTS];
    return useQuery({ queryKey, queryFn, enabled: isLoggedIn });
  };

  const updateMailingLists = async (mailingLists: string[]): Promise<null> => {
    const response = await apiClient.customers.updateMailingListsForCustomer({
      customerId: '_me',
      data: mailingLists,
    });
    queryClient.invalidateQueries({ queryKey: [KEY_MY_MAILING_LISTS] });
    return response;
  };

  const redeemDiscountCodeAsync = async (code: string) => {
    return await apiClient.customers.redeemDiscountCode({
      discountCode: code,
      market: market.value,
      language: localeCookie.value,
    });
  };

  const redeemDiscountCode = (code?: ComputedRef) => {
    const queryFn = async () => {
      if (!code?.value) return null;
      return redeemDiscountCodeAsync(code.value);
    };
    const enabled = computed<boolean>(() => !!code?.value);
    const queryKey = [code];
    return useQuery({
      queryKey,
      queryFn,
      refetchOnWindowFocus: false,
      enabled,
    });
  };

  const getReferralCode = () => {
    const queryFn = async () => {
      const referral = await apiClient.customers.retrieveReferralCode({
        customerId: '_me',
      });

      return referral.code;
    };
    const queryKey = [KEY_MY_REFERRAL_CODE];
    return useQuery({
      queryKey,
      queryFn,
      enabled: isLoggedIn,
      staleTime: 30 * 1000,
    });
  };

  return {
    getMyCustomerInfo,
    getMyCustomerState,
    invalidateCustomerState,
    updateLanguageSelection,
    registerCustomer,
    createDraftCustomer,
    finaliseDraftCustomer,
    setBillingAddress,
    getCustomerZendeskToken,
    enrollToLoyaltyProgram,
    getMailingLists,
    updateMailingLists,
    redeemDiscountCodeAsync,
    redeemDiscountCode,
    getReferralCode,
  };
}
