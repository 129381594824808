import type {
  CalendarPauseReason,
  Customer,
  Subscription,
} from '@ruokaboksi/api-client';
import posthog from 'posthog-js';

type PersonProperties = {
  inLoyaltyProgram?: Customer['inLoyaltyProgram'];
  loyaltyLevel?: Customer['loyaltyLevel'];
  createdAt?: Customer['createdAt'];
  language?: Customer['language'];
  subscriptionStatus?: Subscription['status'];
  boxTypeId?: Subscription['boxTypeId'];
  defaultNumberOfRecipes?: Subscription['defaultNumberOfRecipes'];
};

export type EventProperties = {
  numberOfRecipes?: number;
  numberOfAdditionalProducts?: number;
  orderValue?: number;
  activationSource?:
    | 'activationModal'
    | 'deliveryCalendar'
    | 'subscriptionToggle'
    | 'deliveryWeekActivate'
    | 'selectedWeekToggle';
  pauseReason?: CalendarPauseReason;
  discountCode?: string;
  isReferralCode?: boolean;
  signupMethod?: string;
};

export const EVENT_NAMES = {
  SUBSCRIPTION_ACTIVATED: 'Subscription Activated',
  SUBSCRIPTION_PAUSED: 'Subscription Paused',
  EXTRA_RECIPE_ADDED: 'Extra Recipe Added',
  DELIVERY_UPDATED: 'Delivery Updated',
  REFERRAL_CODE_SHARED: 'Referral code shared',
  DISCOUNT_CODE_APPLIED: 'Discount code applied',
  STORE_PURCHASE_COMPLETED: 'Store purchase completed',
  SIGNUP_COMPLETED: 'Signup completed',
} as const;

export const postHogSendCustomEvent = (
  event: (typeof EVENT_NAMES)[keyof typeof EVENT_NAMES],
  eventProperties?: EventProperties
) => {
  posthog.capture(event, eventProperties);
};

export const postHogSetPersonProperties = (properties: PersonProperties) => {
  posthog.setPersonProperties(properties);
};

export const postHogIdentifyUser = (
  userId: string,
  properties: PersonProperties
): void => {
  posthog.identify(userId, properties);
};
