import { type MarketId, markets } from '@ruokaboksi/api-client';
import type { CookieRef } from '#app';

/**
 * Composable for fetching cookie about the current market.
 * @returns The current market id.
 */
export default function useCurrentMarket() {
  const market: CookieRef<MarketId> = useCookie('market', {
    sameSite: 'strict',
    secure: true,
  });
  const { subscription } = useCustomerSubscriptions();
  const { getDeliveryAreaForSubscription } = useDeliveriesApi();
  const { data: deliveryArea } = getDeliveryAreaForSubscription(subscription);

  const { countryProperties } = useCurrentCountry();

  if (
    deliveryArea.value?.market &&
    (!market.value || market.value !== deliveryArea.value.market)
  ) {
    market.value = deliveryArea.value.market;
  } else if (!market.value) {
    market.value = countryProperties.market;
  }

  const marketProperties =
    markets.find((m) => m.id === market.value) ?? markets[0];

  return {
    market,
    marketProperties,
  };
}
