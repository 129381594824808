import type { BillingAddressUpdate, Customer } from '@ruokaboksi/api-client';
import { z } from 'zod';

// .pick() and .partial does not work well with nested objects in latest zod
const TrackingConsentModel = z.object({
  trackingConsentAnalytics: z.boolean().optional().nullable(),
  trackingConsentMarketing: z.boolean().optional().nullable(),
  trackingConsentPreferences: z.boolean().optional().nullable(),
});

export type TrackingConsent = z.infer<typeof TrackingConsentModel>;

export type CustomerFormData = Pick<
  Customer,
  'email' | 'firstName' | 'lastName' | 'phone' | 'language'
> & {
  billingAddress: BillingAddressUpdate;
  id?: string;
};
